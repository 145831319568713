<template>
  <div class="container">
    <div class="mainBox">
      <!-- 控制面板 -->
      <div class="box">
        <div class="rows">
          <div class="ts_item">
            <div class="item" @click="addPacks">
              <img
                class="itemIcon"
                src="../assets/img/indexImg/index01.svg"
                alt=""
              />
              <div class="item_right">
                <div class="mainTitle">添加包裹</div>
                <div class="label">
                  今日:
                  <span style="color: #f00">{{
                    datas.package_add_count || 0
                  }}</span>
                  票
                </div>
              </div>
            </div>
            <div class="ts_arrow">
              <div class="cloArrow">
                <div class="cloLine"></div>
                <div class="clo_sanjiao"></div>
              </div>
            </div>
          </div>
          <div class="rowArrow1">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>
          <!-- <------------------------------------------------------------>
          <div class="item item1" @click="RejectReturn">
            <img class="itemIcon" src="../assets/img/9.1.3.svg" alt="" />
            <div class="item_right">
              <div class="mainTitle">拒收退货</div>
              <div class="label">
                未退货:
                <span style="color: #f00">{{
                  datas.rejection_count || 0
                }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow1">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>

          <div class="item item1" @click="ParcelClaim">
            <img class="itemIcon" src="../assets/img/9.1.2.svg" alt="" />
            <div class="item_right">
              <div class="mainTitle">包裹认领</div>
              <div class="label">
                未认领:
                <span style="color: #f00">{{
                  datas.package_no_claim_count || 0
                }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow1">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>

          <div class="item item1" @click="excepHandling">
            <img class="itemIcon" src="../assets/img/9.6.1.svg" alt="" />
            <div class="item_right">
              <div class="mainTitle">异常处理</div>
              <!-- <div class="label">
              待处理: <span style="color: #f00">{{datas.exception_no_handle_count}}</span> 票
            </div> -->
              <div class="label">
                待处理:
                <span style="color: #f00">{{
                  datas.exception_no_handle_count || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日处理:
                <span style="color: #333">{{
                  datas.exception_handle_count || 0
                }}</span>
                票
              </div>
            </div>
          </div>
        </div>
        <!-- 第二行 -->
        <div class="rows">
          <div class="item" @click="SignFor">
            <img
              class="itemIcon"
              src="../assets//img/indexImg/index07.svg"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">到库签收</div>
              <div class="label">
                待签收:
                <span style="color: #f00">{{
                  datas.package_wait_signfor_count || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日签收:
                <span style="color: #333">{{
                  datas.package_signfor_count
                }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>

          <div class="item" @click="PackageWarehousing">
            <img
              class="itemIcon"
              src="../assets/img/indexImg/index02.svg"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">包裹入库</div>
              <div class="label">
                待入库:
                <span style="color: #f00">{{
                  datas.package_wait_into_count || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日入库:
                <span style="color: #333">{{
                  datas.package_into_count || 0
                }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>

          <div class="item" @click="PackageShelf">
            <img
              class="itemIcon"
              src="../assets/img/indexImg/rksj.png"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">包裹上架</div>
              <div class="label">
                待上架:
                <span style="color: #f00">{{
                  datas.upframe_wait_count || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日上架:
                <span style="color: #333">{{ datas.upframe_count || 0 }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>

          <div class="ts_item" @click="WarehouseInventory">
            <div class="item">
              <img
                class="itemIcon"
                src="../assets/img/indexImg/ckkc.png"
                alt=""
              />
              <div class="item_right">
                <div class="mainTitle">仓库库存</div>
                <div class="label">
                  总:
                  <span style="color: #f00">{{
                    datas.storage_stock || 0
                  }}</span>
                  票
                </div>
              </div>
            </div>
            <div class="ts_arrow">
              <div class="cloArrow">
                <div class="cloLine"></div>
                <div class="clo_sanjiao"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 第三行 -->
        <div class="rows">
          <div class="ts_item" @click="WarehousePackaging">
            <div class="item">
              <img
                class="itemIcon"
                src="../assets/img/indexImg/ckdb.png"
                alt=""
              />
              <div class="item_right">
                <div class="mainTitle">仓库打包</div>
                <div class="label">
                  待打包:
                  <span style="color: #f00">{{
                    datas.storage_wait_pack || 0
                  }}</span>
                  票
                </div>
                <div class="label">
                  今日打包:
                  <span style="color: #333">{{ datas.storage_pack || 0 }}</span>
                  票
                </div>
              </div>
            </div>
            <div class="ts_arrow">
              <div class="cloArrow">
                <div class="cloLine"></div>
                <div class="clo_sanjiao"></div>
              </div>
            </div>
          </div>
          <div class="rowArrow_f">
            <div class="row_sanjiao"></div>
            <div class="rowLine"></div>
          </div>

          <div class="item" @click="PickingOffTheShelf">
            <img
              class="itemIcon"
              src="../assets/img/indexImg/jhxj.png"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">拣货下架</div>
              <div class="label">
                待拣货:
                <span style="color: #f00">{{ datas.wait_downframe || 0 }}</span>
                票
              </div>
              <div class="label">
                今日拣货:
                <span style="color: #333">{{ datas.downframe || 0 }}</span> 票
              </div>
            </div>
          </div>
          <div class="rowArrow_f">
            <div class="row_sanjiao"></div>
            <div class="rowLine"></div>
          </div>

          <div class="item" @click="ApplicationPackaging">
            <img
              class="itemIcon"
              src="../assets/img/indexImg/index03.svg"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">申请打包</div>
              <div class="label">
                今日申请:
                <span style="color: #f00">{{
                  datas.application_pack || 0
                }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow_f">
            <div class="row_sanjiao"></div>
            <div class="rowLine"></div>
          </div>

          <div class="item" @click="InspectionHandling">
            <img
              class="itemIcon"
              src="../assets/img/indexImg/index10.svg"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">验货处理</div>
              <div class="label">
                待处理:
                <span style="color: #f00">{{
                  datas.wait_examine_goods || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日处理:
                <span style="color: #f00">{{ datas.examine_goods || 0 }}</span>
                票
              </div>
            </div>
          </div>
        </div>
        <!-- 第四行 -->
        <div class="rows" style="margin-bottom: 20px">
          <div class="item" @click="ReceivableBill">
            <img
              class="itemIcon"
              src="../assets/img/indexImg/cwsh.png"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">财务审核</div>
              <div class="label">
                待审核:
                <span style="color: #f00">{{
                  datas.finance_wait_approve || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日审核:
                <span style="color: #333">{{
                  datas.finance_approve || 0
                }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>

          <div class="item" @click="goWriteOff">
            <img
              class="itemIcon"
              src="../assets//img/indexImg/index05.png"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">会员付款</div>
              <div class="label">
                待付款:
                <span style="color: #f00">{{
                  datas.member_wait_payment || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日付款:
                <span style="color: #333">{{ datas.member_payment || 0 }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>

          <div class="item" @click="DeliveryConfirmation">
            <img
              class="itemIcon"
              src="../assets/img/indexImg/index04.svg"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">出库确认</div>
              <div class="label">
                待出库:
                <span style="color: #f00">{{
                  datas.outstorage_wait_confirm || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日出库:
                <span style="color: #333">{{
                  datas.outstorage_confirm || 0
                }}</span>
                票
              </div>
            </div>
          </div>
          <div class="rowArrow">
            <div class="rowLine"></div>
            <div class="row_sanjiao"></div>
          </div>
          <div class="item" @click="CustomerSignature">
            <img
              class="itemIcon"
              src="../assets/img/indexImg/index07.svg"
              alt=""
            />
            <div class="item_right">
              <div class="mainTitle">客户签收</div>
              <div class="label">
                待签收:
                <span style="color: #f00">{{
                  datas.customer_wait_sign || 0
                }}</span>
                票
              </div>
              <div class="label">
                今日签收:
                <span style="color: #333">{{ datas.customer_sign || 0 }}</span>
                票
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
export default {
  components: {},
  data() {
    return {
      datas: {
        package_add_count: 0, // 包裹今天添加次数
        rejection_count: 0, // 拒收退货次数
        package_no_claim_count: 0, // 包裹未认领次数
        exception_no_handle_count: 0, // 异常未处理处理次数
        exception_handle_count: 0, // 今天异常处理次数
        package_wait_signfor_count: 0, // 到库待签收次数
        package_signfor_count: 0, // 到库签收次数
        package_wait_into_count: 0, // 包裹待入库
        package_into_count: 0, // 包裹入库
        upframe_wait_count: 0, // 待上架
        upframe_count: 0, // 已上架
        storage_stock: 0, // 仓库库存
        storage_wait_pack: 0, // 仓库待打包
        storage_pack: 0, // 仓库打包
        wait_downframe: 0, // 待拣货下架
        downframe: 0, // 拣货下架
        application_pack: 0, // 申请打包
        wait_examine_goods: 0, // 待验货处理
        examine_goods: 0, // 验货处理
        finance_wait_approve: 0, // 财务待审核
        finance_approve: 0, // 财务已审核
        member_wait_payment: 0, // 待付款
        member_payment: 0, // 已付款
        outstorage_wait_confirm: 0, // 待出库确认
        outstorage_confirm: 0, // 已出库确认
        customer_wait_sign: 0, // 客户待签收
        customer_sign: 0, // 客户已签收
      },
    };
  },
  created() {
    this.getData();
    // let uk = window.localStorage.getItem("UserKey") || "";
    // if (!uk) {
    //   this.$router.push("/login");
    // }
  },
  mounted() {},
  watch: {},
  methods: {
    getData() {
      Api.getReportCount().then((res) => {
        if (res.data.status == "success") {
          let arrs = res.data.result || [];
          let datas = this.datas;
          for (var k in datas) {
            for (var i = 0; i < arrs.length; i++) {
              if (arrs[i].reportType == k) {
                datas[k] = arrs[i].count;
              }
            }
          }
          this.datas = datas;
        }
      });
    },

    isCanJump(val) {
      let hasPermiss = this.hasPermiss(val);
      if (!hasPermiss) {
        this.$message.error("您没有权限访问哦");
      }
      return hasPermiss;
    },

    //添加包裹
    addPacks() {
      //
      let hasPermiss = this.isCanJump("bggl_zongHeChaXun:add");
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/BusinessManagement/addPacks",
      });
    },
    //拒收退货
    RejectReturn() {
      let hasPermiss = this.isCanJump("bgrk_juShouTuiHuo:query");
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/BusinessManagement/Warehousing/RejectReturn",
      });
    },
    //包裹认领
    ParcelClaim() {
      let hasPermiss = this.isCanJump("bggl_baoGuoRenLing:claim:list");
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/exceptionhandling/Parcelclaim",
      });
    },
    //异常处理
    excepHandling() {
      let hasPermiss = this.isCanJump("ycgl_yiChangBaoGuo:query");
      if (!hasPermiss) {
        return false;
      }
      this.$router.push({
        path: "/exceptionhandling/Abnormalpackage",
      });
    },
    //到库签收
    SignFor() {
      let hasPermiss = this.isCanJump("bgrk_daoKuQianShou:notsign:query");
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/BusinessManagement/Warehousing/SignFor",
      });
    },
    //包裹入库
    PackageWarehousing() {
      let hasPermiss = this.isCanJump("bgrk_baoGuoRuKu:instock_query");
      if (!hasPermiss) {
        return false;
      }
      this.$router.push({
        path: "/BusinessManagement/Warehousing/PackageWarehousing",
      });
    },
    //包裹上架
    PackageShelf() {
      let hasPermiss = this.isCanJump("bgrk_baoguoshangjia:query");
      if (!hasPermiss) {
        return false;
      }
      this.$router.push({
        path: "/BusinessManagement/Warehousing/PackageShelf",
      });
    },
    //仓库库存
    WarehouseInventory() {
      let hasPermiss = this.isCanJump("bgrk_cangkukucun:query");
      if (!hasPermiss) {
        return false;
      }
      this.$router.push({
        path: "/BusinessManagement/Warehousing/Inventory",
        // path: "/BusinessManagement/ExWarehouse/WarehouseInventory",
      });
    },
    //仓库打包
    WarehousePackaging() {
      let hasPermiss = this.isCanJump("dbck_cangKuDaBao:query");
      if (!hasPermiss) {
        return false;
      }
      this.$router.push({
        path: "/BusinessManagement/ExWarehouse/WarehousePackaging",
      });
    },
    //拣货下架
    PickingOffTheShelf() {
      let hasPermiss = this.isCanJump("dbck_jianHuoXiaJia:query");
      if (!hasPermiss) {
        return false;
      }
      this.$router.push({
        path: "/BusinessManagement/ExWarehouse/PickingOffTheShelf",
      });
    },
    //申请打包
    ApplicationPackaging() {
      let hasPermiss = this.isCanJump("bgrk_shenQingDaBao:query");
      if (!hasPermiss) {
        return false;
      }
      if (this.Tools.NoHuoMao()) {
        this.$router.push({
          path: "/pages/ApplicationPackag_new",
        });
      } else {
        this.$router.push({
          path: "/BusinessManagement/Warehousing/ApplicationPackaging",
        });
      }
    },
    //验货处理
    InspectionHandling() {
      let hasPermiss = this.isCanJump("bgrk_yanHuoGuanLi:query");
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/BusinessManagement/Warehousing/InspectionManagement",
      });
    },
    //出库确认
    DeliveryConfirmation() {
      let hasPermiss = this.isCanJump("dbck_chuKuQueRen:query");
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/BusinessManagement/ExWarehouse/DeliveryConfirmation",
      });
    },
    //客户签收
    CustomerSignature() {
      let hasPermiss = this.isCanJump("dbck_keHuQianShou:query");
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/BusinessManagement/ExWarehouse/CustomerSignature",
      });
    },
    //财务审核
    ReceivableBill() {
      let hasPermiss = this.isCanJump("ysgl_yingShouJiFei:query");
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/Finance/ReceivableBilling",
      });
    },
    //会员付款
    goWriteOff() {
      let hasPermiss = this.isCanJump("ysgl_shouKuanHeXiao:write:query");
      console.log("hasPermiss", hasPermiss);
      if (!hasPermiss) {
        return false;
      }

      this.$router.push({
        path: "/Finance/WriteOff",
      });
    },
  },
};
</script>

<style scoped lang="scss">
$borderColor: #ef7c1b;

.container {
  text-align: center;
  background-color: #fff;

  .mainBox {
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    overflow: auto;
  }

  .box {
    display: inline-block;
    margin: 0 auto;
    margin-top: 20px;

    .rows {
      display: flex;
      align-items: center;
      margin-bottom: 80px;

      .item1 {
        border: 1px solid #6d6a6a !important;
      }

      .item {
        cursor: pointer;
        min-width: 156px;
        height: 70px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $borderColor;
        border-radius: 10px;

        .itemIcon {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }

        .item_right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          font-size: 12px;
          color: #666;

          .mainTitle {
            font-size: 12px;
            font-weight: bold;
            display: flex;
            color: #333;
          }

          .label {
            margin-top: 4px;
            font-size: 10px;
          }
        }
      }

      .ts_item {
        position: relative;

        .ts_arrow {
          position: absolute;
          left: 80px;

          .cloArrow {
            display: flex;
            flex-direction: column;
            align-items: center;

            .cloLine {
              width: 2px;
              height: 60px;
              background-color: $borderColor;
            }

            .clo_sanjiao {
              width: 0;
              height: 0;
              border: 10px solid #fff;
              border-top-color: $borderColor;
            }
          }
        }
      }

      .rowArrow1 {
        display: flex;
        align-items: center;

        .rowLine {
          width: 60px;
          min-width: 40px;
          height: 2px;
          background-color: #ffffff;
        }

        .row_sanjiao {
          width: 0;
          height: 0;
          border: 10px solid #fff;
          border-left-color: #ffffff;
        }
      }

      //   横箭头
      .rowArrow {
        display: flex;
        align-items: center;

        .rowLine {
          width: 60px;
          min-width: 40px;
          height: 2px;
          background-color: $borderColor;
        }

        .row_sanjiao {
          width: 0;
          height: 0;
          border: 10px solid #fff;
          border-left-color: $borderColor;
        }
      }

      //   横箭头
      .rowArrow_f {
        display: flex;
        align-items: center;

        .rowLine {
          width: 60px;
          min-width: 40px;
          height: 2px;
          background-color: $borderColor;
        }

        .row_sanjiao {
          width: 0;
          height: 0;
          border: 10px solid #fff;
          border-right-color: $borderColor;
        }
      }
    }
  }
}
</style>
